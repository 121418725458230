import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Lead } from '../../store/reducers/leadsReducer';


interface OwnProps {
  lead: Lead;
  deleteLead: (lead: Lead) => void;
}

type Props = OwnProps;

const DeleteLead: React.FC<Props> = ({ lead, deleteLead }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span 
        className="d-inline-flex justify-content-center align-items-center rounded-circle bg-danger text-white m-1"
        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
        onClick={handleShow}
      >
        <i className="bi bi-trash"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Lead?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {lead.company_name}</p>
          <p>Description: {lead.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { deleteLead(lead); handleClose(); }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(DeleteLead);