import { createRoot } from 'react-dom/client'
import App from "./components/App"
import './index.css'
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import {store, persistor} from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import LoadingScreen from './components/layout/LoadingScreen';

createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
