import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';
import { Lead } from '../../store/reducers/leadsReducer';
import { AppState } from '../../store';
import { useSelector } from 'react-redux';

interface OwnProps {
  lead: Lead;
  viewLeadImage: (lead: Lead ) => void;
}

interface StateProps {
}

interface DispatchProps {}

type Props = DispatchProps & OwnProps & StateProps;

const ViewImage: React.FC<Props> = ({ lead, viewLeadImage }) => {
  const imageUrl = useSelector((state: AppState) => state.leads.imageUrl);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    viewLeadImage(lead);
  }

  return (
    <>
      <span 
        className="d-inline-flex justify-content-center align-items-center rounded-circle bg-success text-white m-1"
        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
        onClick={handleShow}
      >
        <i className="bi bi-eye" style={{ fontSize: '1rem' }}></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={imageUrl} width={300} height={'auto'} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewImage;