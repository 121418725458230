// LoadingScreen.tsx
import React from 'react';
import './LoadingScreen.css';

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <div className="loading-icon">
        <div className="shield-icon">
          <div className="shield"></div>
        </div>
      </div>
      <h2>Loading Secure Environment...</h2>
    </div>
  );
};

export default LoadingScreen;
