import { Container, Button, Table, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { filterLeads, fetchLeads, deleteLead, updateLead, viewLeadImage } from "../../store/actions/leadsActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import LeadSummary from "./LeadSummary";
import { AppDispatch } from "../../store";
import { Lead } from "../../store/reducers/leadsReducer";

interface AppState {
  leads: {
    list: Record<string, Lead>;
    message?: string;
  };
}

interface AppProps {
  leads: {
    list: Record<string, Lead>;
    message?: string;
  };
}

interface OwnProps {}

interface DispatchProps {
  filterLeads: (search: string) => void;
  fetchLeads: () => void;
  deleteLead: (lead: Lead) => void;
  updateLead: (lead: Lead) => void;
  viewLeadImage: (lead: Lead) => void;
}

type Props = AppProps & OwnProps & DispatchProps;

const LeadsPage: React.FC<Props> = ({ leads, filterLeads, fetchLeads, deleteLead, updateLead, viewLeadImage }) => {
  const keys = Object.keys(leads?.list || {});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search.length) {
      filterLeads(search);
    } else {
      fetchLeads();
    }
  }, [search, filterLeads, fetchLeads]);

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="d-none d-md-block">LEADS ({keys.length})</h2>
        </Col>
        <Col xs="auto" className="m-1 d-flex flex-row-reverse">
          <NavLink className="m-1" to="/lead/add">
            <Button variant="primary">Add Lead</Button>
          </NavLink>
          <Form.Group as={Row} className="m-1" controlId="search">
            <Col>
              <Form.Control
                placeholder="search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Table bordered responsive id="LeadsTable">
        <thead>
          <tr className="align-middle table-primary">
            <th className="text-center">Company Name</th>
            <th className="text-center">Description</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? (
            <tr>
              <td colSpan={3}>No Records Available</td>
            </tr>
          ) : (
            keys.map((id) => (
              <LeadSummary key={id} lead={leads.list[id]} deleteLead={deleteLead} updateLead={updateLead} viewLeadImage={viewLeadImage}/>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  leads: state.leads,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  filterLeads: (search: string) => dispatch(filterLeads(search)),
  fetchLeads: () => dispatch(fetchLeads()),
  deleteLead: (lead: Lead) => dispatch(deleteLead(lead)),
  updateLead: (lead: Lead) => dispatch(updateLead(lead)),
  viewLeadImage: (lead: Lead) => dispatch(viewLeadImage(lead)),
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(LeadsPage));