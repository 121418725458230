import { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavigationBar from './layout/NavigationBar';
import SignIn from './auth/SignIn';
import RequireAuth from "./auth/RequireAuth";
import { Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { stateChange } from "../store/actions/authActions";
import LeadsPage from "./leads/LeadsPage";
import CreatePaperLead from "./leads/CreatePaperLead";
import CreateQRLead from "./leads/CreateQRLead";
import { AppDispatch } from "../store";

interface AppState {
  auth: any;
}

interface StateProps {
  auth: any;
}

interface DispatchProps {
  stateChange: () => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const App: React.FC<Props> = ({ auth, stateChange }) => {
  const { category } = auth;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    stateChange();
    if (category) {
      setLoading(false);
    }
  }, [category, stateChange]);

  const renderRoutes = useCallback(() => (
    <Routes>
      <Route path='/signin' element={category && category !== 'LOGIN' ? <Navigate to='/' replace /> : <SignIn />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<CreatePaperLead />} />
        <Route path='/leads' element={<LeadsPage />} />
        <Route path='/lead/add/paper' element={<CreatePaperLead />} />
        <Route path='/lead/add/qr' element={<CreateQRLead />} />
      </Route>
    </Routes>
  ), [category]);

  if (loading) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <BrowserRouter>
      <div className="App">
        {category !== 'LOGIN' && <NavigationBar />}
        {renderRoutes()}
      </div>
      <div style={{ height: '100px' }}></div>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  stateChange: () => dispatch(stateChange())
});

const mapStateToProps = (state: AppState) => state;

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(App);