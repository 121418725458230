import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, clearError } from '../../store/actions/authActions';
import { Container, Form, Button, Alert, Spinner, Row, Col, Image, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import bg from '../../assets/bg.jpg'; // Replace with a relevant cybersecurity image
import logo from "../../assets/Logo.png";
import { loadReCaptcha } from './recaptcha';
import { RootState, AppDispatch } from '../../store';
import './SignIn.css'; // Import the CSS file

const SignIn: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authError = useSelector((state: RootState) => state.auth.authError);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(authError);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    captcha: ''
  });

  useEffect(() => {
    setError(authError);
    if (authError) {
      setDisabled(false);
    }
  }, [authError]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [id]: value
    }));
  }, []);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(clearError());
    setDisabled(true);
    loadReCaptcha('6LcMjmYqAAAAAEeW4k5FytaqVTTG_h6iH9MkbtMG', 'submit').then((token) => {
      const updatedCredentials = {
        ...credentials,
        captcha: token
      };
      setCredentials(updatedCredentials);
      dispatch(signIn(updatedCredentials));
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        password: ''
      }));
    });
  }, [dispatch, credentials]);

  return (
    <div className="signin-container">
      <Image src={bg} className="signin-background" />
      <Container className="signin-form-container text-center">
        <Card className="p-4 shadow-sm" style={{ maxWidth: '400px', width: '100%' }}>
          <Card.Body>
            <Image className="mb-4" src={logo} width={100} height={100} />
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  value={credentials.email}
                  onChange={handleChange}
                  autoComplete="username"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Password"
                  value={credentials.password}
                  onChange={handleChange}
                  autoComplete="current-password"
                />
              </Form.Group>

              {error && (
                <Form.Group className="mb-3" controlId="errorMessage">
                  <Alert key="error" variant="danger">
                    <div className="d-flex justify-content-between">
                      <span>{error}</span>
                      <span onClick={() => dispatch(clearError())} style={{ cursor: 'pointer' }}>X</span>
                    </div>
                  </Alert>
                </Form.Group>
              )}

              <Form.Group>
                <Button disabled={disabled} id="sign-in" variant="primary" type="submit" className="w-100">
                  {disabled ? <Spinner animation="border" size="sm" role="status" /> : 'Sign In'}
                </Button>
                <Button variant="link" id="forgot" onClick={() => navigate('/reset')} className="w-100 mt-2">
                  Forgot Password?
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default SignIn;