import React, { useState, ChangeEvent } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Lead } from '../../store/reducers/leadsReducer';

interface OwnProps {
  lead: Lead;
  updateLead: (lead: Lead ) => void;
}

interface StateProps {
}

interface DispatchProps {}

type Props = DispatchProps & OwnProps & StateProps;

const EditLead: React.FC<Props> = ({ lead, updateLead }) => {
  const [show, setShow] = useState(false);
  const [editLead, setEditLead] = useState<Lead>(lead);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setEditLead((prevLead) => ({ ...prevLead, [id]: value }));
  };

  return (
    <>
      <span 
        className="d-inline-flex justify-content-center align-items-center rounded-circle bg-primary text-white m-1"
        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
        onClick={handleShow}
      >
        <i className="bi bi-pencil" style={{ fontSize: '1rem' }}></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3" controlId="description">
            <Form.Label className='fw-bold' column sm="2">Context / Description: </Form.Label>
            <Col sm="10">
              <Form.Control as='textarea' required rows={3} onChange={handleChange} value={editLead.description} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="company_name">
            <Form.Label className='fw-bold' column sm="2">Company Name: </Form.Label>
            <Col sm="10">
              <Form.Control type='name' onChange={handleChange} value={editLead.company_name} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="name">
            <Form.Label className='fw-bold' column sm="2">Name: </Form.Label>
            <Col sm="10">
              <Form.Control type='name' onChange={handleChange} value={editLead.name} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="designation">
            <Form.Label className='fw-bold' column sm="2">Designation: </Form.Label>
            <Col sm="10">
              <Form.Control type='name' onChange={handleChange} value={editLead.designation} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="contact">
            <Form.Label className='fw-bold' column sm="2">Contact: </Form.Label>
            <Col sm="10">
              <Form.Control type='name' onChange={handleChange} value={(editLead.contact)} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="email">
            <Form.Label className='fw-bold' column sm="2">Email: </Form.Label>
            <Col sm="10">
              <Form.Control type='name' onChange={handleChange} value={editLead.email} />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { updateLead(editLead); handleClose(); }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditLead;