import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createCardLead, clearMessage, fetchLeads, getTextFromImage } from '../../store/actions/leadsActions';
import { Container, Form, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { Lead } from '../../store/reducers/leadsReducer';

interface AppState {
  leads: any;
}

interface StateProps {
  leads: any;
}

interface DispatchProps {
  createCardLead: (lead: Lead) => void;
  clearMessage: () => void;
  fetchLeads: () => void;
  getTextFromImage: (file: File) => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const CreatePaperLead: React.FC<Props> = ({ leads, clearMessage, fetchLeads, createCardLead, getTextFromImage }) => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [lead, setLead] = useState<Lead>({
    description: '',
    id: '',
    raw_data: {},
    company_name: '',
    name: '',
    contact: '',
    designation: '',
    email: '',
    website: '',
    image: undefined,
    type: 'card'
  });
  const [imageFile, setImageFile] = useState<File | null>(null); // New state for image file
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    if (leads.extractedData) {
      const jsonData =  leads.extractedData.data.data.candidates[0].content.parts[0].text;
      try {
        const json = JSON.parse(jsonData.replace('```json\n','').replace('```',''));
        setLead(prevLead => ({ ...prevLead, raw_data: json}));
        setProcessing(false);
        setLead(prevLead => ({
          ...prevLead,
          ...json
        }))
      } catch (err: any) {
        setProcessing(false);
        setLead(prevLead => ({ ...prevLead, raw_data: {"error":"could not be processed, image stored for future reference"}}));
      }
    }
  }, [leads.extractedData]);

  useEffect(() => {
    if (leads?.message === 'Lead created successfully') {
      setDisabled(false);
      clearMessage();
    }
  }, [leads, clearMessage]);

  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLead(prevLead => ({ ...prevLead, [id]: value }));
  };

  useEffect(() => {
    if (imageFile) {
      getTextFromImage(imageFile); // Using Firebase Cloud Vision API
    }
  },[imageFile]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProcessing(true);
    const file = e.target.files?.[0];
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith('image/')) {
        setLead(prevLead => ({ ...prevLead, image: file }));
        setImageFile(file);
      } else {
        alert('Please select a valid image file.'); // Feedback for invalid file
        setImageFile(null);
      }
    }
  };

  const reset = () => {
    setLead({
      description: '',
      id: '',
      raw_data: {},
      company_name: '',
      name: '',
      contact: '',
      designation: '',
      email: '',
      website: '',
      image: undefined,
      type: 'card'
    });
    document.getElementById('businessCard')?.setAttribute('Value','');
    setImageFile(null); // Reset image file
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    createCardLead(lead);
    reset();
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3 className='grey-text text-darken-3'>Add Paper Business Card Lead</h3>
        <Form.Group as={Row} className="mb-3" controlId="description">
          <Form.Label className='fw-bold' column sm="2">Context / Description: </Form.Label>
          <Col sm="10">
            <Form.Control as='textarea' required rows={3} onChange={handleChange} value={lead.description} />
          </Col>
        </Form.Group>
        {/* File input for business card image */}
        <Form.Group as={Row} className="mb-3" controlId="businessCard">
          <Form.Label className='fw-bold' column sm="2">Image</Form.Label>
          <Col sm="10">
            <Form.Control 
              type="file" 
              accept="image/*" 
              onChange={handleFileChange} 
            />
            <Button className='mt-2' variant="info" disabled={true}>
              {imageFile ? (processing ? <Spinner animation='border' size='sm' role='status' /> : 'Card Processed') : 'Upload Card Image'}
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="raw_data">
          <Form.Label className="fw-bold" column sm="2">Processed Info</Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                rows={5}
                value={JSON.stringify(lead.raw_data, null, 2)}  // Display JSON as a readable string
                readOnly  // Make it read-only
              />
            </Col>
          </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="company_name">
          <Form.Label className='fw-bold' column sm="2">Company Name: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.company_name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">Name: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="designation">
          <Form.Label className='fw-bold' column sm="2">Designation: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.designation} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="contact">
          <Form.Label className='fw-bold' column sm="2">Contact: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.contact} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="email">
          <Form.Label className='fw-bold' column sm="2">Email: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.email} />
          </Col>
        </Form.Group>
        <Form.Group>
          <Button disabled={disabled} className='m-1' variant="success" type="submit">
            {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Create'}
          </Button>
          <Button className='m-1' variant="warning" onClick={reset}>
            Reset
          </Button>
          <Button className='m-1' variant="secondary" onClick={() => navigate('/leads')}>
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createCardLead: (lead: Lead) => dispatch(createCardLead(lead)),
  clearMessage: () => dispatch(clearMessage()),
  fetchLeads: () => dispatch(fetchLeads()),
  getTextFromImage: (file: File) => dispatch(getTextFromImage(file)),
});

const mapStateToProps = (state: AppState) => ({
  leads: state.leads,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(React.memo(CreatePaperLead));
