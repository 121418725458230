import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from "react-redux";
import { Container, Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SignInLinks from "./SignInLinks";
import { signOut } from "../../store/actions/authActions";
import { AppDispatch } from '../../store';

interface AuthState {
  user: {
      uid: string;
      email: string;
  };
  category: string;
}

interface AppState {
  auth: AuthState;
}

interface AppProps {
  auth: AuthState;
}

interface DispatchProps {}

interface OwnProps {}

type Props = AppProps & OwnProps & DispatchProps;

interface LogoProps {
  src: string;
  alt: string;
  style?: React.CSSProperties;
}

const Logo = React.forwardRef<HTMLImageElement, LogoProps>(
  ({ src, alt, style }, ref) => (
    <Navbar.Brand>
      <img
        ref={ref}
        alt={alt}
        src={require(`../../assets/${src}`)}
        style={style}
      />
    </Navbar.Brand>
  )
);

const NavigationBar: React.FC<Props> = ({auth}) => {
  const dispatch = useDispatch<AppDispatch>();
  const firstLogoRef = useRef<HTMLImageElement | null>(null);
  const [firstLogoHeight, setFirstLogoHeight] = useState<number>(0);
  const { category, user } = auth;

  useEffect(() => {
    if (firstLogoRef.current) {
      setFirstLogoHeight(firstLogoRef.current.offsetHeight);
    }
  }, [firstLogoRef.current?.offsetHeight]);

  const links = category !== 'LOGIN' ? <SignInLinks /> : null;

  return (
    <Navbar variant="light" expand='lg' sticky="top" style={{ marginBottom: '8px', backgroundColor: 'lightskyblue' }}>
      <Container>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Logo 
          ref={firstLogoRef}
          src="Navbar Logo.png" 
          alt="logo" 
          style={{ width: '20%', height: 'auto', minHeight: '25px' }} 
        />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </div>
      <Navbar.Collapse className="justify-content-end ms-auto" id="basic-navbar-nav">
        <Nav>
          {links}
          {category==="LOGGEDIN" && (
            <NavDropdown
              align="end"
              className="dropdown-menu-start"
              title={
                <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-2" id="tooltip">{user.email}</Tooltip>}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg>
                </OverlayTrigger>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => dispatch(signOut())}>
                <i className="bi bi-box-arrow-in-right" /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state: AppState): AppProps => ({
  auth: state.auth,
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(mapStateToProps, null)(NavigationBar);