import { createAsyncThunk } from '@reduxjs/toolkit';
import { db, storage, functions } from "../../config/fbConfig";
import { ref, child, set, onValue, remove, push } from 'firebase/database';
import { httpsCallable } from 'firebase/functions'
import { getDownloadURL, ref as refs, uploadBytes } from 'firebase/storage';
import { Lead, setLeads, setLead, setLeadMessage, setLeadError, setLeadExtractedMessage, setImageUrl, setExtractedQrMessage } from '../reducers/leadsReducer';
import jsQR from 'jsqr';

const cardLeadsRef = child(ref(db), 'cardLeads');
const handleFirebaseError = (err: any, dispatch: any) => {
  dispatch(setLeadError(err.message));
};

export const createCardLead = createAsyncThunk('leads/createCardLead', async (lead: Lead, { dispatch }) => {
  try {
    if(lead.image===undefined) delete lead.image;
    const newLeadRef = await push(cardLeadsRef, lead);
    const cardRef = refs(storage, `BusinessCards/${newLeadRef.key}`)
    if(lead.image) await uploadBytes(cardRef,lead.image);
    await set(child(newLeadRef,'id'), newLeadRef.key);
    dispatch(setLeadMessage('Lead created successfully'));
  } catch (err: any) {
    handleFirebaseError(err, dispatch);
  }
});

export const createQrLead = createAsyncThunk('leads/createQrLead', async (lead: Lead, { dispatch }) => {
  try {
    if(lead.image===undefined) delete lead.image;
    const newLeadRef = await push(cardLeadsRef, lead);
    const cardRef = refs(storage, `QRCodes/${newLeadRef.key}`)
    if(lead.image) await uploadBytes(cardRef,lead.image);
    await set(child(newLeadRef,'id'), newLeadRef.key);
    dispatch(setLeadMessage('Lead created successfully'));
  } catch (err: any) {
    handleFirebaseError(err, dispatch);
  }
});

export const clearMessage = createAsyncThunk('leads/clearMessage', async (_, { dispatch }) => {
  dispatch(setLeadMessage(''));
});

export const fetchLeads = createAsyncThunk('leads/fetchLeads', async (_, { dispatch }) => {
  onValue(cardLeadsRef, (snapshot) => {
    const leads = snapshot.exists() ? snapshot.val() : {};
    dispatch(setLeads(leads));
  });
});

export const fetchLead = createAsyncThunk('leads/fetchLead', async (id: string, { dispatch }) => {
  const leadRef = child(cardLeadsRef, id);
  onValue(leadRef, (snapshot) => {
    const lead = snapshot.exists() ? snapshot.val() : {};
    dispatch(setLead(lead));
  });
});

export const filterLeads = createAsyncThunk('leads/filterLeads', async (search: string, { dispatch }) => {
  onValue(cardLeadsRef, (snapshot) => {
    const leads = snapshot.exists() ? snapshot.val() : {};
    const filteredLeads = Object.entries(leads)
      .filter(([_, val]: [string, any]) =>
        `${val.name} ${val.description}`.toLowerCase().includes(search.toLowerCase())
      )
      .map(([_, val]: [string, any]) => val);
    dispatch(setLeads(filteredLeads));
  });
});

export const deleteLead = createAsyncThunk('leads/deleteLead', async (lead: Lead , { dispatch }) => {
  try {
    const removeRef = child(cardLeadsRef, lead.id);
    await remove(removeRef);
    await set(child(ref(db), `deletedLeads/${lead.id}`), lead);
    dispatch(setLeadMessage('Lead deleted successfully'));
  } catch (err: any) {
    handleFirebaseError(err, dispatch);
  }
});

export const getTextFromImage = createAsyncThunk('leads/getTextFromImage', async (file: File, { dispatch }) => {
  // Convert file to Base64
  const reader = new FileReader();
  const base64String = await new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      const base64 = reader.result as string;
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file); // Read the file as Base64
  });

  // Call the Firebase Cloud Function to analyze the image
  const analyzeImage = httpsCallable(functions, 'analyzeImage');
  const result = await analyzeImage({base64String});
  
  // Dispatch the extracted text or analysis result
  dispatch(setLeadExtractedMessage(result));  // assuming result contains text
});

export const getTextFromCamera = createAsyncThunk('leads/getTextFromCamera', async (_,{ dispatch }) => {
  return new Promise<string>((resolve, reject) => {
    const video : any = document.createElement('video');
    navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } }  })
      .then((stream) => {
        video.srcObject = stream;
        video.setAttribute('playsinline', 'true');
        video.play();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        const scan = () => {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          // Implement QR code scanning logic here, e.g., using a library
          // Example with jsQR:
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);
          if (code) {
            video.srcObject.getTracks().forEach((track: { stop: () => any; }) => track.stop());
            dispatch(setExtractedQrMessage(code.data));
          } else {
            requestAnimationFrame(scan); // Continue scanning
          }
        };

        scan();
      })
      .catch(err => reject(err));
  });
});


export const updateLead = createAsyncThunk('leads/updateLead', async (lead: Lead, { dispatch }) => {
  try {
    const leadRef = child(cardLeadsRef, lead.id);
    await set(leadRef, lead);
    dispatch(setLeadMessage('Lead updated successfully'));
  } catch (err: any) {
    handleFirebaseError(err, dispatch);
  }
});

export const viewLeadImage = createAsyncThunk('leads/viewLeadImage', async (lead: Lead, {dispatch}) => {
  try {
    let imageUrl;
    if(lead.type==='card')
    imageUrl = await getDownloadURL(refs(storage,`BusinessCards/${lead.id}`));
    else 
    imageUrl = await getDownloadURL(refs(storage, `QRCodes/${lead.id}`))
    dispatch(setImageUrl(imageUrl));
  } catch (err: any) {
    dispatch(setImageUrl('none'));
  }
});