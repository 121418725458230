import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Lead {
  description: string;
  id: string;
  raw_data: any;
  company_name: string;
  name: string;
  contact: any;
  designation: string;
  email: string;
  website: string;
  image?: File;
  type: string;
}

export interface LeadsState {
  message?: string;
  list: {[id: string] : Lead};
  leadError?: string;
  extractedData?: any;
  extractedQrData?: any;
  raw_data: any;
  lead?: Lead;
  imageUrl: string;
}

const initialState: LeadsState = {
  message: '',
  leadError: '',
  list: {},
  raw_data: {},
  imageUrl: 'none'
};

const leadsReducer = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeadError(state, action: PayloadAction<string>) {
      state.leadError = action.payload;
    },
    setLeads(state, action: PayloadAction<any>) {
      state.list = action.payload;
    },
    setLead(state, action: PayloadAction<Lead>) {
      state.lead = action.payload;
    },
    setLeadMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    setLeadExtractedMessage(state, action: PayloadAction<any>) {
      state.extractedData = action.payload;
    },
    setExtractedQrMessage(state, action: PayloadAction<any>) {
      state.extractedQrData = action.payload;
    },
    setImageUrl(state, action: PayloadAction<string>) {
      state.imageUrl = action.payload;
    }
  }
});

export const { setLeadError, setLeads, setLead, setLeadMessage, setLeadExtractedMessage, setImageUrl, setExtractedQrMessage } = leadsReducer.actions;
export default leadsReducer.reducer;