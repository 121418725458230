import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";

const links = [
  { to: '/lead/add/paper', label: 'Add Card' },
  { to: '/lead/add/qr', label: 'Add QR' },
  { to: '/leads', label: 'View'}
];

const SignInLinks: React.FC = () => {
  return (
    <>
      {links.map((link, index) => (
        <Nav.Link as={Link} to={link.to} key={index}>
          {link.label}
        </Nav.Link>
      ))}
    </>
  );
}

export default SignInLinks;