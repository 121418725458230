import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createQrLead, clearMessage, fetchLeads, getTextFromCamera } from '../../store/actions/leadsActions';
import { Container, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { AppDispatch } from '../../store';
import { Lead } from '../../store/reducers/leadsReducer';

interface AppState {
  leads: any;
}

interface StateProps {
  leads: any;
}

interface DispatchProps {
  createQrLead: (lead: Lead) => void;
  clearMessage: () => void;
  fetchLeads: () => void;
  getTextFromCamera: () => void;
}

type Props = StateProps & DispatchProps;

const CreateQRLead: React.FC<Props> = ({ leads, clearMessage, fetchLeads, createQrLead, getTextFromCamera }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [lead, setLead] = useState<Lead>({
    description: '',
    id: '',
    raw_data: {},
    company_name: '',
    name: '',
    contact: '',
    designation: '',
    email: '',
    website: '',
    image: undefined,
    type: 'qr',
  });
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (leads.extractedQrData) {
      console.log('setLead');
      setLead(prevLead => ({ ...prevLead, raw_data: leads.extractedQrData }));
      setProcessing(false);
    }
  }, [leads.extractedQrData]);

  const handleScan = async () => {
    let stream:any;
    if (videoRef.current) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ video: {
          facingMode: { exact: "environment" }
        }});
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      } catch (error) {
        console.error("Error accessing the camera: ", error);
      }
    }
    setProcessing(true);
    try {
      await getTextFromCamera(); // This will trigger scanning as implemented before
      stream.getTracks().forEach((track: { stop: () => any; }) => track.stop());
    } catch (error) {
      console.error("QR scan error: ", error);
    } finally {
      setProcessing(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createQrLead(lead);
    reset();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLead(prevLead => ({ ...prevLead, [id]: value }));
  };

  const reset = () => {
    setLead({
      description: '',
      id: '',
      raw_data: {},
      company_name: '',
      name: '',
      contact: '',
      designation: '',
      email: '',
      website: '',
      image: undefined,
      type: 'qr',
    });
  };

  useEffect(()=> {
    if(leads.extractedQRData)
    {
      setLead(prevLead => ({ ...prevLead, raw_data: leads.extractedQRData}));
      setProcessing(false);
    }
  },[leads.extractedQrData])

  return (
    <Container>
      <h3 className='grey-text text-darken-3'>Add QR Lead</h3>
      
      {/* Video stream for QR scanning */}
      <video ref={videoRef} style={{ width: '100%', height: 'auto' }} />
      
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Col sm="10">
            <Button variant="primary" onClick={handleScan} disabled={processing}>
              {processing ? <Spinner animation="border" size='sm' /> : 'Scan QR Code'}
            </Button>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="raw_data">
          <Form.Label className="fw-bold" column sm="2">Processed Info</Form.Label>
          <Col sm="10">
            <Form.Control
              as="textarea"
              rows={5}
              value={JSON.stringify(lead.raw_data, null, 2)}  // Display JSON as a readable string
              readOnly  // Make it read-only
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="description">
          <Form.Label className='fw-bold' column sm="2">Context / Description: </Form.Label>
          <Col sm="10">
            <Form.Control as='textarea' required rows={3} onChange={handleChange} value={lead.description} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="company_name">
          <Form.Label className='fw-bold' column sm="2">Company Name: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.company_name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">Name: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="designation">
          <Form.Label className='fw-bold' column sm="2">Designation: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.designation} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="contact">
          <Form.Label className='fw-bold' column sm="2">Contact: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.contact} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="email">
          <Form.Label className='fw-bold' column sm="2">Email: </Form.Label>
          <Col sm="10">
            <Form.Control type='name' onChange={handleChange} value={lead.email} />
          </Col>
        </Form.Group>
        <Form.Group>
          <Button className='m-1' variant="success" type="submit">
            Create
          </Button>
          <Button className='m-1' variant="warning" onClick={reset}>
            Reset
          </Button>
        </Form.Group>
      </Form>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createQrLead: (lead: Lead) => dispatch(createQrLead(lead)),
  clearMessage: () => dispatch(clearMessage()),
  fetchLeads: () => dispatch(fetchLeads()),
  getTextFromCamera: () => dispatch(getTextFromCamera()),
});

const mapStateToProps = (state: AppState) => ({
  leads: state.leads,
});

export default connect<StateProps, DispatchProps, {}, AppState>(mapStateToProps, mapDispatchToProps)(CreateQRLead);
