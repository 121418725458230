import React from "react";
import DeleteLead from './DeleteLead';
import { Lead } from "../../store/reducers/leadsReducer";
import EditLead from './EditLead';
import ViewImage from './ViewImage';

interface OwnProps {
  lead: Lead;
  deleteLead: (lead: Lead) => void;
  updateLead: (lead: Lead) => void;
  viewLeadImage: (lead: Lead) => void;
}

type Props = OwnProps;

const LeadSummary: React.FC<Props> = ({ lead, deleteLead, updateLead, viewLeadImage }) => {
  return (
    <tr className="align-middle" >
      <td>{lead.company_name}</td>
      <td>{lead.description}</td>
      <td>
        <EditLead lead={lead} updateLead={updateLead} />
        <DeleteLead lead={lead} deleteLead={deleteLead} />
        <ViewImage lead={lead} viewLeadImage={viewLeadImage} />
      </td>
    </tr>
  );
};

export default React.memo(LeadSummary);